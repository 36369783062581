import React from "react"
import { graphql, useStaticQuery } from "gatsby"

const Footer = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            author
          }
        }
      }
    `
  );
  return(
    <footer class="footer">
      <div class="content has-text-centered">
        <p>
          &copy;{site.siteMetadata.author}
        </p>
      </div>
    </footer>
  )
}

export default Footer;