import React from "react"
import { Link } from "gatsby"

const NavigationBar = ( props ) => {
    const [isActive, setisActive] = React.useState(false)
    return (
        <nav className="navbar has-background-primary" role="navigation" aria-label="main navigation">
            <div className="navbar-brand">
                <Link className="navbar-item" to="/">
                    <img src="/logo_booklooper@300.png" alt={ props.imageCaption} />
                </Link>
                <a
                    href="#"
                    onClick={() => {
                        setisActive(!isActive)
                    }}
                    role="button"
                    className={`navbar-burger burger ${isActive ? 'is-active' : ''}`}
                    aria-label="menu"
                    aria-expanded="false"
                    data-target="navbarBasicExample"
                >
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </a>
            </div>
            <div id="navbarBasicExample"
                className={`navbar-menu ${isActive ? 'is-active' : ''}`}
            >
                <div className="navbar-start is-flex-grow-1 is-justify-content-right">
                <Link to="/" className="navbar-item">
                    Home
                </Link>
                <Link to="/information" className="navbar-item">
                    お知らせ
                </Link>
                <Link to="/document" className="navbar-item">
                    ドキュメント
                </Link>
                <Link to="/faq" className="navbar-item">
                    FAQ
                </Link>
                {/* <Link to="/contact" className="navbar-item">
                    お問い合わせ
                </Link> */}
                </div>
            </div>
        </nav>
    )
}
export default NavigationBar;