import React from "react"
import { graphql, useStaticQuery } from "gatsby"
// import "../styles/mystyles.scss"
import NavigationBar from "./navbar"

const Header = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            author
          }
        }
      }
    `
  );
  return (
    <NavigationBar imageCaption={`${site.siteMetadata.title}`}/>
  )
}

export default Header;