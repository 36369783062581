import React from "react"
import Layout from "../components/layout"

const Contact = () => {
    return (
        <Layout>
            <div className="is-flex is-align-items-center is-justify-content-center">
                <div className="columns is-mobile is-centered">
                    <div className="column">
                        <h1 className="mb-6 mt-6 title has-text-centered has-text-dark">お問い合わせ</h1>
                        <p className="mb-4 mt-4 has-text-centered">BookLooperの利用に関するお問い合わせは、ご利用機関の窓口へお願いいたします。</p>
                        <p className="mb-4 mt-4 has-text-centered">導入に関するお問い合わせは弊社の窓口へお問い合わせください。</p>
                        <div className="mb-6 mt-6 inline-text-centered">
                            <a href="https://www.kccs.co.jp/contact/ja/ict/" target="_blank" rel="noopener noreferrer">ICTに関するお問い合わせ</a>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default Contact;